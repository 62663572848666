<template>
	<div class="wrapper transformer">

		<!-- 查询 -->
		<el-form :inline="true" :model="searchform">
			<el-form-item label="用户姓名">
				<el-input v-model="searchform.name" clearable placeholder="请输入用户姓名"></el-input>
			</el-form-item>
			<el-form-item label="手机号">
				<el-input v-model="searchform.phone" clearable placeholder="请输入手机号"></el-input>
			</el-form-item>
      <el-form-item>
				<el-button type="primary" @click="search">查询</el-button>
			</el-form-item>
		</el-form>

		<!-- 列表 -->
		<el-table :data="tableData" border stripe style="width: 100%" @cell-click="getItem">
			<el-table-column prop="username" label="用户姓名" align="center">
			</el-table-column>
			<el-table-column prop="useraccount" label="登录账号" align="center">
			</el-table-column>
			<el-table-column prop="phone" label="手机号" align="center">
			</el-table-column>
			<el-table-column label="用户类型" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.otheritem == 0">管理员</span>
					<span v-if="scope.row.otheritem == 1">社区</span>
					<span v-if="scope.row.otheritem == 2">第三方平台</span>
				</template>
			</el-table-column>
			<el-table-column prop="rolename" label="部门名称" align="center">
			</el-table-column>
			<el-table-column prop="areaname" label="所属社区" align="center">
			</el-table-column>
		</el-table>


		<div class="paginationbox">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="fenye.pagesizes" :page-size="fenye.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="fenye.totalnum">
			</el-pagination>
		</div>
		
		
	</div>

</template>

<script>
import { getUsers} from '@/api/system'
	export default {
		data() {
			return {
				searchform: {
					name: '',
					phone: '',
				},
				tableData: [],
				userDialogVisible: false,
				currentPage: 1,
				fenye: {
					totalnum: 0,
					pagesizes: [10, 20, 30, 40, 50],
					pagesize: 10
				},
				list: []
			
			}
		},
		mounted() {
			this.getList();
		},
		methods: {
      getItem(row){
        console.log(row)
        this.$emit("getCopyUserId", row)
      },
			
			//列表查询
			search() {		
				this.currentPage = 1;		
				this.getList();
			},
			
			//切换页面条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.fenye.pagesize = val;
				this.currentPage = 1;
				this.getList();
			},
			//切换分页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},

			//获取用户列表
			getList() {
				getUsers({
						userid: this.GLOBAL.adminId(),
						page: this.currentPage,
						pagesize: this.fenye.pagesize,
						name: this.searchform.name,
						phone: this.searchform.phone
				}).then(res=>{
					if(res.result == 200){
						let detail = res.detail;
						let list = detail.list;
						this.fenye.totalnum = detail.totalRow;
						this.tableData = list;
					} else {
						this.$message.error(res.description);
					}
				})
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">

.el-select{
	width: 100%;
}
.paginationbox{
	text-align: right;
	margin-top: 20px;
}
	


	.el-textarea .el-textarea__inner {
		resize: none;
	}

	.loudong{
		.tree{
			height: 600px;
			overflow: auto;
		}
		.btns{
			margin-top: 20px;
			text-align: center;
		}
	}
</style>
